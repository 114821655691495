import { create } from 'zustand';
import api from '../utils/api';

const getUserDeviceLocation = async () => {
  try {
    const resp = await fetch('https://ipapi.co/json/').then((response) => response.json());

    return { country: resp.country_name, city: resp.city } as Record<string, string>;
  } catch {
    return '';
  }
};

function getUserTimeZoneOffset() {
  const date = new Date();
  const timeZoneOffset = -date.getTimezoneOffset();
  const hours = Math.floor(timeZoneOffset / 60);
  const minutes = Math.abs(timeZoneOffset % 60);
  const sign = hours >= 0 ? '+' : '-';

  return `UTC${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )}`;
}

function detectOS() {
  const userAgent = window.navigator.userAgent;

  if (/Windows NT 10/.test(userAgent)) return 'Windows 10';
  if (/Windows NT 6.3/.test(userAgent)) return 'Windows 8.1';
  if (/Windows NT 6.2/.test(userAgent)) return 'Windows 8';
  if (/Windows NT 6.1/.test(userAgent)) return 'Windows 7';
  if (/Macintosh|Mac OS X/.test(userAgent)) return 'macOS';
  if (/Linux/.test(userAgent)) return 'Linux';
  if (/Android/.test(userAgent)) return 'Android';
  if (/iPhone|iPad|iPod/.test(userAgent)) return 'iOS';

  return 'Unknown OS';
}

interface AuthStore {
  isLoggedIn: boolean;
  login: (accessToken: string) => void;
  logout: () => void;
}

const useAuthStore = create<AuthStore>((set) => ({
  isLoggedIn: false,
  login: async (payload: string) => {
    localStorage.setItem('accessToken', payload);
    set({ isLoggedIn: true });
    if (!localStorage.getItem('user_location')) {
      try {
        const location = await getUserDeviceLocation();
        localStorage.setItem('user_location', JSON.stringify(location));
      } catch (err) {
        throw new Error(`Could not get location: ${JSON.stringify(err)}`);
      }
    }
    const deviceType = /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
    const deviseOS = detectOS();
    const locationFromStorage = localStorage.getItem('user_location');

    const userTimezone = getUserTimeZoneOffset();

    // Stats event "APP_OPENED"
    api.post('/users/stats', {
      name: 'app_opened',
      device: deviceType,
      os: deviseOS,
      timezone: userTimezone,
      country: locationFromStorage ? JSON.parse(locationFromStorage).country : '',
      city: locationFromStorage ? JSON.parse(locationFromStorage).city : '',
    });

    return;
  },
  // For testing purposes
  logout: () => {
    set({ isLoggedIn: false });
  },
}));

export default useAuthStore;
