import { useQuery } from 'react-query';
import api from '../../shared/utils/api';

export type Banner = {
  id: number;
  banner_text: string;
  button_text: string;
  button_link?: string;
  image_url?: string;
  order: number;
};

export const useBanners = () => {
  return useQuery(['banners'], {
    queryFn: async () => {
      const res = await api.get<Array<Banner>>('/tournaments/banners');
      return res;
    },
    retry: false,
    refetchInterval: 100000,
  });
};
