import { create } from 'zustand';

type InitStore = {
  initialized: boolean;
  initApp: () => void;
};

export const useInitStore = create<InitStore>((set) => ({
  initialized: false,
  initApp: () => set(() => ({ initialized: true })),
}));
