import { Leader, useLeaders } from '../../../../app/hooks/useLeaders';
import CoinIcon from '../../../../shared/Icons/CoinIcon';
import { formatNumberToK } from '../../../../shared/utils/formatNumber';
import { twMerge } from 'tailwind-merge';

import firstPlaceSvg from '../../../../assets/first-place.svg';
import secondPlaceSvg from '../../../../assets/second-place.svg';
import thirdPlaceSvg from '../../../../assets/third-place.svg';

type LeaderItemProps = Leader & {
  isCurrentUser?: boolean;
};

const placeToIcon = (place_num: number) => {
  if (place_num === 1) return <img src={firstPlaceSvg} className="w-4 h-4" />;
  if (place_num === 2) return <img src={secondPlaceSvg} className="w-4 h-4" />;
  if (place_num === 3) return <img src={thirdPlaceSvg} className="w-4 h-4" />;
};

const LeaderItem = ({ name, coins_count, place_num, isCurrentUser }: LeaderItemProps) => {
  return (
    <div
      className={twMerge(
        'flex text-[#FFFFFFBF] justify-between px-2 py-4',
        isCurrentUser && 'bg-[#121212] rounded-lg mt-2',
      )}
    >
      <div className="flex gap-4 items-center">
        {!!place_num && place_num <= 3 ? (
          placeToIcon(place_num)
        ) : (
          <div className="text-[10px] font-normal">{place_num > 999 ? '+999' : place_num}</div>
        )}
        <div className="text-[16px]">{name}</div>
      </div>
      <div className="flex gap-1 items-center font-semibold">
        {formatNumberToK(coins_count,null)} <CoinIcon size={16} />
      </div>
    </div>
  );
};

const LeadersList = () => {
  const { data } = useLeaders();
  if (!data) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between text-[#FFFFFF80] pt-4 text-[14px]">
        <div>{data?.total_count || 0} participants</div>
        <div>Total points</div>
      </div>
      <div className="flex flex-col">
        {data?.current_user && (
          <LeaderItem key={data.current_user.id} isCurrentUser {...data.current_user} />
        )}
        {data?.leaders.map((leader) => <LeaderItem key={leader.id} {...leader} />)}
      </div>
    </div>
  );
};

export default LeadersList;
