import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import VConsole from 'vconsole';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
// import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';

import App from './App.tsx';
import './index.css';

const queryClient = new QueryClient();

// const tagManagerArgs = {
//   gtmId: import.meta.env.VITE_GA_TOKEN,
// };

// TagManager.initialize(tagManagerArgs);

clarity.init(import.meta.env.VITE_CLARITY_TOKEN);

if (import.meta.env.VITE_MODE === 'dev-local' || import.meta.env.VITE_MODE === 'dev') {
  //@ts-ignore
  const vConsole = new VConsole();
}
Sentry.init({
  dsn: 'https://200fc9e60dfcd5f7cccf9c5e38f37833@o4508139120689152.ingest.de.sentry.io/4508217957220432',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </QueryClientProvider>
  </StrictMode>,
);
