import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import GrayCoinIcon from '../../../../shared/Icons/GrayCoinIcon';
import { useBanners } from '../../../../app/hooks/useBanners';
import { Link } from 'react-router-dom';
import api from '../../../../shared/utils/api';

const SliderSkeleton = () => {
  return (
    <div className="w-full h-[100px]">
      <div className="animate-pulse bg-[#121212] w-full h-full rounded-[16px]"></div>
    </div>
  );
};

const InfoSlider = () => {
  const { data, isLoading } = useBanners();

  if (!data || isLoading) {
    return <SliderSkeleton />;
  }

  return (
    <div>
      <Swiper
        slidesPerView={1}
        direction={'vertical'}
        spaceBetween={500}
        centeredSlides={true}
        watchOverflow={false}
        pagination={{
          clickable: false,
        }}
        autoplay
        mousewheel={{
          enabled: true,
          sensitivity: 0.2,
        }}
        modules={[Pagination, Autoplay, Mousewheel]}
        className="mySwiper text-white z-10"
      >
        {data.map((banner) => (
          <SwiperSlide className="relative flex justify-between">
            <div className="w-[65%] text-[14px] flex flex-col text-left justify-between h-full">
              <div
                style={{
                  fontFamily: 'SFPro',
                  fontWeight: 400,
                }}
              >
                {banner.banner_text}
              </div>
              <Link
                to={banner.button_link || ''}
                onClick={() => {
                  api.post('/users/stats', {
                    name: 'banner_clicked',
                    banner_id: banner.id,
                  });
                }}
              >
                <div className="font-bold font-[SFPro] text-[#BEF73D] text-[14px]">
                  {banner.button_text}
                </div>
              </Link>
            </div>
            {banner.image_url ? (
              <div className="w-[119px] h-[68px] mr-2">
                <img src={banner.image_url} width={167} height={118} alt={banner.banner_text} />
              </div>
            ) : (
              <>
                <div className="absolute top-12 right-[35%]">
                  <GrayCoinIcon size={36} />
                </div>

                <div className="absolute top-2 right-[20%]">
                  <GrayCoinIcon size={36} />
                </div>

                <div className="absolute top-16 right-[10%]">
                  <GrayCoinIcon size={24} />
                </div>
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default InfoSlider;
