import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { appRoutes } from '../shared/constants/appRoutes';

import Loader from './Loader';
import MainLayout from './mainLayout';

// import ShakePage from '../modules/shake/ShakePage';
import TournamentPage from '../modules/tournaments/TournamentPage';
import AboutTournamentPage from '../modules/tournament/Tournament';
// import FriendsPage from '../modules/friends/FriendsPage';
// import HomePage from '../modules/Home';
import ErrorPage from '../modules/Error';
// import OnboardingPage from '../modules/Onboarding/OnboardingPage';
import { lazy, Suspense } from 'react';

const HomePage = lazy(() => import('../modules/Home'));
// const OnboardingPage = lazy(() => import('../modules/Onboarding/OnboardingPage'));
// const TournamentPage = lazy(() => import('../modules/tournaments/TournamentPage'));
const FriendsPage = lazy(() => import('../modules/friends/FriendsPage'));
const ShakePage = lazy(() => import('../modules/shake/ShakePage'));

const routes: RouteObject[] = [
  {
    path: appRoutes.Home,
    element: (
      <Suspense fallback={<Loader />}>
        <HomePage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  // {
  //   path: appRoutes.Onboarding,
  //   element: (
  //     <Suspense fallback={<Loader />}>
  //       <OnboardingPage />
  //     </Suspense>
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  {
    path: appRoutes.Earn,
    element: (
      <MainLayout>
        <Suspense fallback={<Loader />}>
          <ShakePage />
        </Suspense>
      </MainLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: appRoutes.Friends,
    element: (
      <MainLayout>
        <Suspense fallback={<Loader />}>
          <FriendsPage />
        </Suspense>
      </MainLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: appRoutes.Tournaments,
    handle: {
      title: 'Tournaments',
    },
    element: (
      <MainLayout>
        {/* <Suspense fallback={<Loader />}> */}
        <TournamentPage />
        {/* </Suspense> */}
      </MainLayout>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: appRoutes.Tournament,
    handle: {
      title: 'About',
    },
    element: (
      <MainLayout>
        <AboutTournamentPage />
      </MainLayout>
    ),
    errorElement: <ErrorPage />,
  },
  // Redirect any unknown path to home

  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <Navigate to={appRoutes.Home} replace />,
  },
];

export const history = createBrowserRouter(routes, {
  future: {
    // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
    v7_normalizeFormMethod: true,
  },
});

const Router = () => {
  return <RouterProvider router={history} fallbackElement={<Loader />} />;
};

export default Router;
