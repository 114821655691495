import images from '../assets';
const ErrorPage = () => {
  return (
    <div className="flex items-center flex-col-reverse">
      <div className="flex flex-col justify-center items-center h-[100vh]">
        <img className="mb-[16px]" src={images.errorDino} alt="error" />
        <h1 className="text-white mb-[8px] text-center  text-[20px] font-[SFPro] font-bold">
          Something went wrong
        </h1>
        <p className="mb-[16px] text-center text-[#FFFFFFBF] font-[SFPro] text-[16px]">Try again</p>
        <button
          className="bg-[#BEF73D] block p-[12px] text-[#1E1E1E] text-center font-[SFPro] text-[16px] w-[150px] rounded-[28px]"
          onClick={() => {
            window.location.pathname = '/';
          }}
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
