import { IconProps } from '../../../shared/types/icons';

export const TournamentsIcon = ({ size, isActive }: IconProps) => {
  const color = isActive ? '#F2F2F7' : '#868686';

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4626 4.17499H19.7347C21.0057 4.17499 21.6407 4.17499 21.973 4.57398C22.3043 4.97403 22.1667 5.57304 21.8905 6.77213L21.4777 8.57129C20.8544 11.2743 18.579 13.2851 15.8167 13.7M4.70426 4.17499H3.43215C2.16109 4.17499 1.52504 4.17499 1.19378 4.57398C0.862523 4.97403 1.00011 5.57304 1.27633 6.77213L1.68908 8.57129C2.31244 11.2743 4.58785 13.2851 7.35009 13.7M11.5834 16.875C9.81176 16.875 8.27083 18.2137 7.47497 20.1886C7.09397 21.1326 7.64007 22.1666 8.36397 22.1666H14.8018C15.5267 22.1666 16.0718 21.1326 15.6918 20.1886C14.896 18.2137 13.3551 16.875 11.5834 16.875Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5834 16.875C14.7796 16.875 17.4731 11.941 18.2827 5.22274C18.506 3.36643 18.6182 2.43721 18.0255 1.71861C17.4328 1 16.475 1 14.5605 1H8.60636C6.69079 1 5.73406 1 5.14139 1.71861C4.54873 2.43721 4.66197 3.36643 4.88422 5.2238C5.69384 11.941 8.38729 16.875 11.5834 16.875Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
