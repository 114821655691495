import axios, { AxiosResponse } from 'axios';
import { history } from '../../app/Router';

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const defaults = {
  baseUrl: import.meta.env.VITE_API_URL,
  // baseUrl: 'https://prod-api.shakeup.cc/api/v1',
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : undefined,
  }),
};

type ApiMethod = 'get' | 'post' | 'put' | 'delete';

interface ApiVariables {
  [key: string]: any;
}

// @ts-ignore
const api = <T>(method: ApiMethod, url: string, variables?: ApiVariables, headers = false) => {
  return new Promise<T>((resolve, reject) => {
    axios({
      url: `${defaults.baseUrl}${url}`,
      method,
      headers: defaults.headers(),
      data: method !== 'get' ? variables : undefined,
    }).then(
      (response: AxiosResponse<T>) => {
        resolve(response.data);
      },
      (error) => {
        if (error.status >= 500 && error.status === 401) {
          history.navigate('/error');
        }

        if (error.response) {
          console.log(error.response.data);
          //   if (error.response.data.error.status === 401) {
          //     location.replace('/login');
          //   }
          reject(error.response.data);
        }
      },
    );
  });
};

export default {
  get: <T>(url: string, variables?: ApiVariables, headers?: any) =>
    api<T>('get', url, variables, headers),
  // @ts-ignore
  post: <T>(url: string, variables?: ApiVariables, headers?: any) =>
    api<T>('post', url, variables, headers),
  // @ts-ignore
  put: (url: string, variables?: ApiVariables, headers?: any) => api('put', url, variables, false),
  delete: (url: string, variables?: ApiVariables) => api('delete', url, variables, false),
};
