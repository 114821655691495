import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SHAKE_THRESHOLD } from '../constants/shakes';
import { useMutation, useQueryClient } from 'react-query';
import { IShake, IShakeResponse } from '../../modules/shake/ShakePage';
import api from '../utils/api';
interface CoinsStore {
  shakesDetected: number;
  addShake: (multiplier: number) => void;
  requestAccess: () => void;
  fallenCoins: number;
  shouldUpdate: boolean;
  setShouldUpdate: (shouldUpdate: boolean) => void;
  setFallenCoins: (multiplier: number) => void;
  clearFallenCoins: () => void;
  setShakesDetected: (shakesDetected: number) => void;
  shakeStartedTimestamp: Date | null;
  setShakeStartedTimestamp: (date: Date | null) => void;
}

export const usePersistCoinsStore = () => {
  const store = useCoinsStore();
  const { shouldUpdate, setShouldUpdate, shakeStartedTimestamp, setShakeStartedTimestamp } =
    useCoinsStore();
  const queryClient = useQueryClient();

  const { mutate: submit } = useMutation({
    mutationFn: (data: IShake) => api.post<IShakeResponse>('/shakes/', data),
    onSuccess: async () => {
      queryClient.invalidateQueries(['profile']);
      setShakeStartedTimestamp(null);
    },
  });

  useEffect(() => {
    if (shouldUpdate) {
      submit({
        total_shakes: store.shakesDetected + store.fallenCoins,
        shakes: store.fallenCoins,

        start_datetime: shakeStartedTimestamp || new Date(),
        end_datetime: new Date(),
      });

      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  return {
    ...store,
  };
};

export const useCoinsStore = create(
  persist<CoinsStore>(
    (set) => ({
      shakesDetected: 0,
      fallenCoins: 0,
      shouldUpdate: false,
      shakeStartedTimestamp: null,
      setShakeStartedTimestamp: (date: Date | null) => set(() => ({ shakeStartedTimestamp: date })),
      setShouldUpdate: (shouldUpdate: boolean) => set(() => ({ shouldUpdate })),
      setFallenCoins: (multiplier: number) =>
        set((state) => ({ fallenCoins: state.fallenCoins + multiplier })),
      clearFallenCoins: () => set(() => ({ fallenCoins: 0 })),
      setShakesDetected: (shakesDetected: number) => set(() => ({ shakesDetected })),
      addShake: (multiplier: number) =>
        set((prev) => ({ shakesDetected: prev.shakesDetected + multiplier })),
      requestAccess: async () => {
        if (isFeatureSupported()) {
          // IOS
          const deviceMotionEvent = DeviceMotionEvent as unknown as DeviceMotionEventiOS;

          if (typeof deviceMotionEvent.requestPermission === 'function') {
            let permission: PermissionState;
            try {
              permission = await deviceMotionEvent.requestPermission();
            } catch (err) {
              return false;
            }
            if (permission !== 'granted') {
              return false;
            }
          }

          return true;
        } else {
          return true;
        }
      },
    }),
    {
      name: 'coins',
    },
  ),
);

interface DeviceMotionEventiOS extends DeviceMotionEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>;
}

const isFeatureSupported = () => {
  return (
    typeof DeviceMotionEvent !== 'undefined' &&
    typeof (DeviceMotionEvent as unknown as DeviceMotionEventiOS).requestPermission === 'function'
  );
};

type NullNumber = null | number;

type Coordinates = {
  x: NullNumber;
  y: NullNumber;
  z: NullNumber;
};

const useShakeDetection = () => {
  const shakeThreshold = SHAKE_THRESHOLD;
  const defaultInterval = 1000;
  const maxIntensity = 15;
  const minInterval = 300;
  const [shakesDetected, setShakesDetected] = useState(0);
  // @ts-ignore
  const [error, setError] = useState(null);

  const lastAcceleration = useRef<Coordinates>({ x: null, y: null, z: null });
  const lastShakeTime = useRef(0);

  const calculateDynamicInterval = (intensity: number) => {
    if (intensity >= maxIntensity) {
      return minInterval;
    }

    const scaledInterval =
      defaultInterval - (intensity / maxIntensity) * (defaultInterval - minInterval);
    return Math.max(scaledInterval, minInterval);
  };

  const handleMotionEvent = (event: DeviceMotionEvent) => {
    const acceleration = event.accelerationIncludingGravity;

    if (!acceleration) return;

    const { x, y, z } = acceleration;

    const { x: lastX, y: lastY, z: lastZ } = lastAcceleration.current;

    if (lastX !== null && lastY !== null && lastZ !== null) {
      const deltaX = Math.abs(lastX - Number(x));
      const deltaY = Math.abs(lastY - Number(y));
      const deltaZ = Math.abs(lastZ - Number(z));

      const intensity = Math.sqrt(deltaX * deltaX + deltaY * deltaY + deltaZ * deltaZ);

      const dynamicInterval = calculateDynamicInterval(intensity);

      const currentTime = Date.now();

      if (currentTime - lastShakeTime.current > dynamicInterval) {
        if (deltaX > shakeThreshold || deltaY > shakeThreshold || deltaZ > shakeThreshold) {
          updateShakes();
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

          lastShakeTime.current = currentTime;
        }
      }
    }

    // Update the last acceleration values
    lastAcceleration.current = { x, y, z };
  };

  const revokeAccessAsync = async (): Promise<void> => {
    window.removeEventListener('devicemotion', handleMotionEvent);
  };

  const revokeAccess = useCallback(revokeAccessAsync, []);

  const shakeCountRef = useRef(0);

  const updateShakes = useCallback(() => {
    shakeCountRef.current += 1;
    setShakesDetected(shakeCountRef.current);
  }, []);

  useEffect(() => {
    window.addEventListener('devicemotion', handleMotionEvent);

    return () => {
      window.removeEventListener('devicemotion', handleMotionEvent);
    };
  }, []);

  useEffect(() => {
    return (): void => {
      revokeAccess();
    };
  }, [revokeAccess]);

  const syntheticMotionOnClick = () => {
    setShakesDetected((prev) => {
      return prev + 1;
    });
  };

  return {
    shakesDetected: Math.floor(shakesDetected),
    shakes: shakesDetected,
    reset: () => setShakesDetected(0),
    syntheticMotionOnClick,
  };
};

export default useShakeDetection;
