import coinSrc from '../../../../assets/coin.png';
import { useEffect, useState } from 'react';

export const getRandomNumber = (min: number, max: number) => Math.random() * (max - min) + min;

type CoinObject = {
  id: number;
  left: string;
  speed: string;
  size: string;
  time: number;
};

const AnimatedCoins = () => {
  const [coins, setCoins] = useState<Array<CoinObject>>([]);

  const spawnDiv = () => {
    const id = Date.now();

    const speed = getRandomNumber(5, 10);
    const newDiv = {
      id,
      left: `${getRandomNumber(10, 90)}%`,
      speed: `${speed}s`,
      size: `${getRandomNumber(20, 40)}px`,
      time: speed,
    };
    setCoins((prevDivs: Array<CoinObject>) => [...prevDivs, newDiv]);

    setTimeout(() => {
      setCoins((prevDivs: Array<CoinObject>) => prevDivs.filter((div) => div.id !== id));
    }, speed * 3000);
  };

  useEffect(() => {
    const interval = setInterval(spawnDiv, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="z-0">
      {coins.map((div) => (
        <img
          src={coinSrc}
          key={div.id}
          id={div.id.toString()}
          width={div.size}
          height={div.size}
          className="falling-div"
          style={{
            left: div.left,
            animationDuration: div.speed,
          }}
        />
      ))}
    </div>
  );
};

export default AnimatedCoins;
