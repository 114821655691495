const GrayCoinIcon = ({ size = 32 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1812 1.54553V3.09099H26.2721V4.63644H27.8175V6.1819H29.363V7.72735H30.9084V10.8183H32.4539V23.1819H30.9084V26.2728H29.363V27.8183H27.8175V29.3637H26.2721V30.9092H23.1812V32.4546H10.8175V30.9092H7.72662V29.3637H6.18116V27.8183H4.63571V26.2728H3.09025V23.1819H1.5448V10.8183H3.09025V7.72735H4.63571V6.1819H6.18116V4.63644H7.72662V3.09099H10.8175V1.54553H23.1812ZM21.6357 4.63644H12.363V6.1819H9.27207V7.72735H7.72662V9.27281H6.18116V12.3637H4.63571V21.6364H6.18116V24.7274H7.72662V26.2728H9.27207V27.8183H12.363V29.3637H21.6357V27.8183H24.7266V26.2728H26.2721V24.7274H27.8175V21.6364H29.363V12.3637H27.8175V9.27281H26.2721V7.72735H24.7266V6.1819H21.6357V4.63644Z"
        fill="#656565"
      />
      <rect x="13.9152" y="8.40662" width="6.72527" height="1.86813" fill="#656565" />
      <rect x="13.9152" y="23.2582" width="6.72527" height="1.86813" fill="#656565" />
      <rect x="12.5135" y="9.34058" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="12.7941" y="15.5055" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="12.7941" y="22.3242" width="2.14835" height="1.96154" fill="#656565" />
      <rect x="13.4473" y="17.0934" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="13.4473" y="21.4835" width="2.14835" height="1.12088" fill="#656565" />
      <rect x="14.1016" y="18.4944" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="14.1016" y="20.6429" width="2.14835" height="1.21429" fill="#656565" />
      <rect x="12.0466" y="10.2747" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="9.61804" y="11.5825" width="2.14835" height="1.49451" fill="#656565" />
      <rect x="9.05798" y="15.132" width="1.86813" height="1.86813" fill="#656565" />
      <rect x="9.80347" y="16.533" width="1.77473" height="1.86813" fill="#656565" />
      <rect x="11.4856" y="18.1208" width="2.14835" height="0.934066" fill="#656565" />
      <rect x="8.49695" y="13.0769" width="1.86813" height="2.19302" fill="#656565" />
      <rect x="12.0466" y="14.3846" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="19.7993" y="9.34058" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="19.6119" y="15.5055" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="19.6119" y="22.3242" width="2.14835" height="1.96154" fill="#656565" />
      <rect x="18.9578" y="17.0934" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="18.9578" y="21.4835" width="2.14835" height="1.12088" fill="#656565" />
      <rect x="18.2103" y="18.4944" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="18.2103" y="20.6429" width="2.14835" height="1.21429" fill="#656565" />
      <rect x="15.2217" y="19.4286" width="3.73626" height="1.4011" fill="#656565" />
      <rect x="20.2662" y="10.2747" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="22.6948" y="11.5825" width="2.14835" height="1.49451" fill="#656565" />
      <rect x="22.9753" y="15.132" width="1.86813" height="1.86813" fill="#656565" />
      <rect x="22.4143" y="16.533" width="1.77473" height="1.86813" fill="#656565" />
      <rect x="20.8263" y="18.1208" width="2.14835" height="0.934066" fill="#656565" />
      <rect x="23.7227" y="13.0769" width="1.86813" height="2.19302" fill="#656565" />
      <rect x="20.2662" y="14.3846" width="2.14835" height="1.86813" fill="#656565" />
      <rect x="11.7661" y="10.6484" width="2.14835" height="4.2033" fill="#656565" />
      <rect x="20.5468" y="10.6484" width="2.14835" height="4.2033" fill="#656565" />
    </svg>
  );
};

export default GrayCoinIcon;
