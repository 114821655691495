import { Sheet } from 'react-modal-sheet';
import { Path, useForm, UseFormRegister, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { useClickAway } from '@uidotdev/usehooks';
import { useSnackbar } from 'notistack';

import tournamentPlaceholder from '../../assets/tournamentPlaceholder.webp';
import youtubeSvg from '../../assets/youtube.svg';
import tiktokSvg from '../../assets/tiktok.svg';
import instagramSvg from '../../assets/instagram.svg';
import videoSvg from '../../assets/video.svg';
import infoSvg from '../../assets/info.svg';
import greenShareSvg from '../../assets/green-share.svg';
import rejectedSvg from '../../assets/rejected.svg';
import approvedSvg from '../../assets/approved-prize.svg';

import { useTournament } from '../../app/hooks/useTournaments';
import CoinIcon from '../../shared/Icons/CoinIcon';
import { formatNumberToK } from '../../shared/utils/formatNumber';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { Trigger } from '../tournaments/components/TabsMenu';
import { useTournamentParticipants } from '../../app/hooks/useTournamentParticipants';
import React from 'react';
import { patterns } from '../../shared/constants/patterns';
import formatActionType from '../../shared/utils/formatActionType';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../shared/utils/api';
import { useProfile } from '../../app/hooks/useProfile';

const actionTypesToSvg = {
  INSTAGRAM: instagramSvg,
  TIKTOK: tiktokSvg,
  YOUTUBE: youtubeSvg,
};

interface IFormValues {
  tiktok: string;
  youtube: number;
  instagram: string;
}

type InputProps = {
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  placeholder: string;
  name: Path<IFormValues>;
  status?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isLast: boolean;
  error?: string;
};

const prizeNameToIcon = (name: string) => {
  if (!name) return undefined;

  if (name.toLowerCase().includes('instagram')) return 'INSTAGRAM';
  if (name.toLowerCase().includes('tiktok')) return 'TIKTOK';
  if (name.toLowerCase().includes('youtube')) return 'YOUTUBE';
};

const mapStatus = (status: string) => {
  if (status === 'IN_REVIEW') return 'IN REVIEW';
  if (status === 'APPROVED') return 'APPROVED';
  if (status === 'REJECTED') return 'REJECTED';
};

const Input = ({
  label,
  register,
  required,
  placeholder,
  name,
  status,
  isLast,
  error,
  ...props
}: InputProps) => {
  return (
    <div className={twMerge('flex flex-col gap-2', isLast && 'mb-4')}>
      <div className="flex justify-between items-center font-[SFPro]">
        <label className="text-[14px] text-[#FFFFFF] font-medium">{label}</label>
        {status && (
          <span
            className={twMerge(
              `text-[12px] flex gap-2 items-center font-semibold text-[#FFFFFF]`,
              status === 'IN_REVIEW' && `text-[#FF9500]`,
              status === 'APPROVED' && `text-[#29CC6A]`,
              status === 'REJECTED' && `text-[#FC5555]`,
            )}
          >
            {mapStatus(status)} {status === 'REJECTED' && <img src={rejectedSvg} />}
          </span>
        )}
      </div>
      <div className="flex flex-col">
        <input
          disabled={status === 'APPROVED'}
          placeholder={placeholder}
          className={twMerge(
            'bg-[#1A1A1A] text-[#F0F0F0] p-3 rounded-[8px] font-[Inter] text-[16px] font-normal outline-none',
            status === 'REJECTED' && 'text-[#FC5555]',
            error && 'focus:outline-[#FC5555]',
            !error && 'focus:outline-[#29CC6A]',
          )}
          {...register(name, {
            required,
            pattern: {
              value: patterns.url,
              message: 'Please enter a valid URL',
            },
          })}
          {...props}
        />
        <span className="text-[#FC5555] text-[10px] pt-1 pl-1">{error}</span>
      </div>
    </div>
  );
};

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields)
      .map((key) => {
        // @ts-ignore
        const dirtyValue = dirtyValues(dirtyFields[key], allValues[key]);

        // @ts-ignore
        if (dirtyValue !== '') {
          return [key, dirtyValue];
        }

        return [key, undefined];
      })
      .filter(([_, value]) => value !== undefined), // Filter out undefined values
  );
};

const AboutTournamentPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [isFocused, setFocused] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useTournament(id);

  const { data: participants, isLoading: isLoadingParticipants } = useTournamentParticipants(id);
  const [isOpen, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const {data:info} = useProfile()

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors, isDirty },
    setError,
  } = useForm<IFormValues>({
    values: data?.form,
  });
  const ref = useClickAway(() => {
    setOpen(false);
  });

  const submit = useMutation({
    mutationKey: ['submitActions'],
    mutationFn: (data: Record<string, string>) =>
      api.post('/tournaments/' + id + '/complete_actions', data),
    onSuccess: () => {
      queryClient.invalidateQueries(['tournament', id]);
      enqueueSnackbar('Done! Wait for review.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    },
    onError: (error: { detail: { message: string; validation_errors: string[] } }) => {
      console.log(error);
      if (error && error.detail && Array.isArray(error.detail.validation_errors)) {
        error.detail.validation_errors.map((type) => {
          setError(type as any, { message: `Hmm.. it doesn't seem to be a valid ${type} link` });
        });
        return;
      }
      throw new Error('Could not submit actions');
    },

    //  try {
    //    // @ts-ignore
    //    const res = await ;

    //    await queryClient.invalidateQueries(['tournament', tournamentId]);
    //  } catch (err) {
    //    throw new Error('Could not submit actions');
    //  }
  });
  const multiplier = info?.active_multipliers?.tournament_multiplier || null
  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate('/tournaments');
    });

    return () => {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(() => {});
    };
  }, []);

  const submittedActions = React.useMemo(() => {
    if (!data?.actions) return 0;

    return data.actions.filter((action: any) => action.user_action !== null).length;
  }, [data?.actions]);

  const onSubmit: SubmitHandler<IFormValues> = (values: IFormValues) => {
    // @ts-ignore
    submit.mutate(dirtyValues(dirtyFields, values));

    reset({ ...values });
  };

  const usedActions = React.useMemo(() => {
    if (!data?.actions) return [];

    return data.actions.filter((a: any) => a.name !== 'Upload Video');
  }, [data?.actions]);

  useEffect(() => {
    const t = setTimeout(() => {
      setIsReady(true);
    }, 400);
    return () => clearInterval(t);
  }, []);

  if (!data || isLoading || isLoadingParticipants || !isReady) {
    return (
      <div className=" h-full w-full py-4 ">
        <div className="bg-[#1A1A1A] rounded-2xl h-full w-full animate-pulse"></div>
      </div>
    );
  }
  return (
    <div className="font-[SFPro] mb-2 mt-4 h-full">
      <Tabs.Root className="flex flex-col w-full h-full shadow-blackA2 " defaultValue="about">
        <Tabs.List className="shrink-0 flex font-[SFPro] font-bold mb-4" aria-label="Tab">
          <Trigger value="about" name="About" onClick={() => {}} />
          <Trigger name="Leaders" value="leaders" onClick={() => {}} />
        </Tabs.List>
        <div className="h-full overflow-y-auto no-scrollbar pb-8">
          <img
            src={data?.image_url || tournamentPlaceholder}
            width={1358}
            height={768}
            className={'w-full rounded-xl mb-4 h-[145px] object-cover bg-[#1A1A1A]'}
          />

          <Tabs.Content
            className="grow rounded-xl outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
            value="about"
          >
            <h1 className="font-[SFPro] font-semibold text-[#FFFFFFBF] text-[16px] pb-2">
              🔥 {data.name}
            </h1>
            <div className="text-[#FFFFFF80] text-[14px] font-normal font-[SFPro]">
              {data?.description}
            </div>
            <button
              onClick={() => {
                setOpen(true);

                api.post('/users/stats', {
                  name: 'tournament_participate_clicked',
                  tournament_id: id,
                });
              }}
              className="bg-[#FFFFFF] text-[#2C2C2C] border-[1px] border-[#2C2C2C] rounded-[10px] text-[16px] w-full p-2 mt-4 mb-4 font-medium"
            >
              Participate {submittedActions} / {usedActions.length}
            </button>

            <Sheet
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              detent={isFocused ? 'full-height' : 'content-height'}
              className={isFocused ? 'full-sheet' : ''}
            >
              <Sheet.Container ref={ref}>
                <Sheet.Header />
                <Sheet.Content className="px-2">
                  {!isFocused && (
                    <h1 className="font-[SFPro] font-bold text-[#FFFFFF] text-[24px] pb-2">
                      Submit video
                    </h1>
                  )}

                  <form onSubmit={handleSubmit(onSubmit)} className="pb-4">
                    <div className="flex flex-col gap-4">
                      {usedActions.map((action: any, i: number) => {
                        return (
                          <Input
                            name={action.action_type.toLowerCase()}
                            label={action.name}
                            register={register}
                            status={action.user_action?.status}
                            placeholder={action.description}
                            required={false}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            isLast={i === usedActions.length - 1}
                            // @ts-ignore
                            error={errors[action?.action_type?.toLowerCase()]?.message}
                          />
                        );
                      })}
                    </div>
                    <button
                      disabled={!isDirty}
                      type="submit"
                      className={twMerge(
                        'bg-[#FFFFFF] text-[#2C2C2C] border-[1px] border-[#2C2C2C] rounded-[10px] text-[16px] w-full p-2 mt-4 mb-4 font-semibold',
                        !isDirty && 'bg-transparent',
                      )}
                    >
                      Save
                    </button>
                  </form>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>

            <h1 className="font-[SFPro] font-semibold text-[#FFFFFFBF] text-[16px] pb-2">Prizes</h1>

            <div className="flex flex-col gap-2">
              {data.prizes.map((action: any) => (
                <div
                  className={twMerge(
                    'bg-[#1A1A1A] flex py-2 px-4 items-center rounded-[8px]',
                    action.user_prize?.status === 'APPROVED' && 'border-[#BEF73D] border-[1px]',
                  )}
                >
                  {action.user_prize?.status === 'APPROVED' ? (
                    <img src={approvedSvg} />
                  ) : (
                    // @ts-ignore
                    <img src={actionTypesToSvg[prizeNameToIcon(action.name)] || videoSvg} />
                  )}
                  <div className="flex flex-col ml-4">
                    <div className="text-[#FFFFFFBF] text-[16px] font-medium">{action.name}</div>
                    <div className="text-[#FFFFFF80] text-[12px] font-normal">{action.name}</div>
                  </div>

                  <div className="flex gap-2 justify-center items-center ml-auto">
                    <CoinIcon size={14} />
                    <div className="text-[#FFFFFF] text-[15px] font-normal w-[30px]">
                      {formatNumberToK(action.prize_size,multiplier)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tabs.Content>
          <Tabs.Content
            className="grow p-5rounded-b-md outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
            value="leaders"
          >
            <div className="p-[1px] bg-gradient-to-r from-[#333b1e] via-[#D7FF7A9C] to-[#475a21] rounded-[16px] mb-4 w-full">
              <div className="bg-gradient-to-r from-[#161813] to-[#232a16] rounded-[16px] flex">
                <img src={infoSvg} className="pl-2" />
                <div className="p-2 font-[Inter] font-normal text-[16px] text-[#FFFFFFBF]">
                  On this screen you can find videos submitted by other users on this tournament.
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {participants?.current_user && (
                <div className="flex items-center justify-between bg-[#121212] p-3 rounded-lg">
                  <div className="text-[#FFFFFFBF]">{participants.current_user.name}</div>
                  <Link target="_blank" to={participants.current_user.result_url || ''}>
                    <div className="flex gap-2 text-[#BEF73D]">
                      {formatActionType(participants.current_user.action_type)}{' '}
                      <img src={greenShareSvg} />
                    </div>
                  </Link>
                </div>
              )}
              {participants?.leaders?.map((p) => (
                <div key={p.name} className="flex items-center justify-between p-3">
                  <div className="text-[#FFFFFFBF]">{p.name}</div>
                  <Link
                    to={p.result_url}
                    target="_blank"
                    onClick={() => {
                      api.post('/users/stats', {
                        name: 'tournament_submission_viewed',
                        tournament_id: id,
                        submission_id: p.id,
                        view_option: p.action_type,
                      });
                    }}
                  >
                    <div className="flex gap-2 text-[#BEF73D]">
                      {formatActionType(p.action_type)} <img src={greenShareSvg} />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
};

export default AboutTournamentPage;
