import { useQuery } from 'react-query';
import { Profile, useProfile } from '../../../app/hooks/useProfile';
import { usePersistCoinsStore } from '../../../shared/hooks/useShakesDetection';
import CoinIcon from '../../../shared/Icons/CoinIcon';
import api from '../../../shared/utils/api';
import AnimatedCoins from './AnimatedCoins';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const ProfileSkeleton = () => {
  return (
    <div className="w-full h-[150px] py-4">
      <div className="animate-pulse bg-[#121212] w-full h-full rounded-[16px]"></div>
    </div>
  );
};
const ProfileData = () => {
  const { data } = useProfile();
  const { shakesDetected, fallenCoins } = usePersistCoinsStore();
  const navigate = useNavigate();
  const { data: userData, isLoading } = useQuery<Profile>({
    queryKey: ['user_data'],
    queryFn: async () => {
      const res = await api.get<Profile>('/users/me');
      return res;
    },
    onError: () => {
      navigate('/error');
    },
    retry: false,
  });

  const balance = useMemo(() => {
    if (userData) {
      return shakesDetected + fallenCoins + (userData.coins_count - userData.coins_shaked);
    }
    return 0;
  }, [fallenCoins, shakesDetected, userData]);
  if (!userData || isLoading) {
    return <ProfileSkeleton />;
  }
  return (
    <div className="pt-4 px-8 pb-4 text-[#FFFFFF] relative h-[150px]">
      <div className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex justify-center items-center gap-2">
          <div className="bg-[#3C3C3C] px-[10px] py-[3px] text-[#FFFFFF] rounded-md filex justify-center items-center">
            {data?.name ? data.name[0].toUpperCase() : '-'}
          </div>
          <div
            className="text-[14px]"
            style={{
              fontFamily: 'SFPro',
              fontWeight: 400,
            }}
          >
            {data?.name}
          </div>
        </div>
        <div className="flex justify-center items-center gap-2">
          <CoinIcon size={40} />
          <div
            className="text-[40px] font-black"
            // style={{
            //   fontFamily: 'SFPro Display',
            //   fontWeight: 900,
            // }}
          >
            {balance}
            {/* !TODO: need refactoring {formatNumber(data?.coins_count || 0)}   */}
          </div>
        </div>
        <div
          className="text-center text-[#FFFFFFBF] text-[14px]"
          style={{
            fontFamily: 'SFPro Display',
            fontWeight: 400,
          }}
        >
          You made {shakesDetected + fallenCoins} shakes
        </div>
      </div>
      <AnimatedCoins />
    </div>
  );
};

export default ProfileData;
