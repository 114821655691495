import { useQuery } from 'react-query';
import api from '../../shared/utils/api';
import { useNavigate } from 'react-router-dom';

const placeholderTournament = {
  id: 4,
  name: 'First Tournament',
  description: 'Participate with your friends to win prizes',
  short_description: 'First Tournament',
  tournament_image: null,
  prize_size: 10000,
  start_date: '2024-10-10T21:05:12',
  finish_date: '2024-11-10T21:05:14',
  users_count: 0,
  labels: [
    {
      id: 1,
      tournament_id: 4,
      label: 'NEW',
      is_custom: false,
      custom_label: null,
    },
  ],
  prizes: [
    {
      id: 1,
      name: 'Upload Video',
      prize_size: 100,
      tournament_id: 4,
      user_prizes: [],
    },
    {
      id: 2,
      name: 'Gain total 1000 views',
      prize_size: 1000,
      tournament_id: 4,
      user_prizes: [],
    },
    {
      id: 3,
      name: 'Gain total 1000 views',
      prize_size: 10000,
      tournament_id: 4,
      user_prizes: [],
    },
    {
      id: 4,
      name: 'Gain total million views',
      prize_size: 1000000,
      tournament_id: 4,
      user_prizes: [],
    },
  ],
  actions: [
    {
      id: 1,
      name: 'Upload TikTok',
      description: 'Upload TikTok',
      tournament_id: 4,
      action_type: 'TIKTOK',
      user_actions: [],
    },
    {
      id: 2,
      name: 'Upload Youtube',
      description: 'Upload Youtube',
      tournament_id: 4,
      action_type: 'YOUTUBE',
      user_actions: [],
    },
    {
      id: 3,
      name: 'Upload Instagram',
      description: 'Upload Instagram',
      tournament_id: 4,
      action_type: 'INSTAGRAM',
      user_actions: [],
    },
    {
      id: 4,
      name: 'Upload Video',
      description: 'Upload Video',
      tournament_id: 4,
      action_type: 'CUSTOM',
      user_actions: [],
    },
  ],
};

export type ITournamentDetails = {
  id: number;
  name: string;
  description: string;
  short_description: string;
  prize_size: number;
  start_date: Date;
  finish_date: Date;
  users_count: number;
  labels: ILabel[];
  image_url: null;
  prizes: IPrize[];
  actions: IAction[];
};

export type IAction = {
  id: number;
  name: string;
  description: string;
  tournament_id: number;
  action_type: string;
  user_action: IUserAction | null;
};

export type IUserAction = {
  id: number;
  status: string;
  result_url: string;
  action_type: string;
};

export type ILabel = {
  id: number;
  tournament_id: number;
  label: string;
  is_custom: boolean;
  custom_label: null;
};

export type IPrize = {
  id: number;
  name: string;
  prize_size: number;
  tournament_id: number;
  user_prize: IUserPrize;
};

export type IUserPrize = {
  id: number;
  status: string;
};
export const useTournaments = () => {
  return useQuery<Array<ITournamentDetails>>({
    queryKey: ['tournaments'],
    queryFn: () => api.get('/tournaments/'),
    placeholderData: [],
  });
};

export const useTournament = (id: string) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: ['tournament', id],
    queryFn: () => api.get<any>('/tournaments/' + id),
    onError: () => {
      navigate('/error');
    },
    retry: false,

    select: (data) => {
      return {
        ...data,
        actions: data.actions.map((action: any) => ({
          ...action,
          value: action.user_actions?.[0]?.result_url || undefined,
          status: action.user_actions?.[0]?.status || undefined,
        })),

        form: {
          tiktok:
            data.actions.find((action: any) => action.action_type === 'TIKTOK')?.user_action
              ?.result_url || '',
          youtube:
            data.actions.find((action: any) => action.action_type === 'YOUTUBE')?.user_action
              ?.result_url || '',
          instagram:
            data.actions.find((action: any) => action.action_type === 'INSTAGRAM')?.user_action
              ?.result_url || '',
        },
      };
    },
    placeholderData: placeholderTournament,
  });
};
