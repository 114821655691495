import { useQuery } from 'react-query';
import api from '../../shared/utils/api';
import { useCoinsStore } from '../../shared/hooks/useShakesDetection';
import { useNavigate } from 'react-router-dom';

export type IOnboardingStep = {
  id: number;
  image_url: string;
  title: string;
  description: string;
  order: number;
  is_active: boolean;
};
export type IRewardType = 'coins_reward' | 'shakes_multiplier' | 'tournament_multiplier' | 'referral_multiplier';
export type IReward = {
  image_url: string;
  type: IRewardType;
  value: number;
};
export type IDailyReward = {
  current_reward: {
    day: number;
    highlighted: boolean;
    id: number;
    rewards: Array<IReward>;
  };
  highlighted_rewards: {
    day: number;
    highlighted: boolean;
    id: number;
    rewards: Array<IReward>;
  }[];
};
export type IActiveMultipliers = {
  referral_multiplier?: number;
  shakes_multiplier?: number;
  tournament_multiplier?: number;
};

export type Profile = {
  name: string;
  id: number;
  shaking_time: number;
  coins_count: number;
  coins_shaked: number;
  shakes_count: number;
  onboarding?: Array<IOnboardingStep>;
  daily_rewards?: IDailyReward;
  active_multipliers?: IActiveMultipliers;
};

export const useProfile = () => {
  const { setShakesDetected, clearFallenCoins } = useCoinsStore();
  const navigate = useNavigate();

  return useQuery<Profile>({
    queryKey: ['profile'],
    queryFn: async () => {
      const res = await api.get<Profile>('/users/me');
      return res;
    },
    onSuccess: (data: Profile) => {
      setShakesDetected(data.shakes_count);
      clearFallenCoins();
    },
    onError: () => {
      navigate('/error');
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 100000,
  });
};
