import { IconProps } from '../types/icons';

const CoinIcon = ({ size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_282_9329)">
        <path
          d="M10.9087 0.727295V1.45457H12.3633V2.18184H13.0906V2.90911H13.8178V3.63639H14.5451V5.09093H15.2724V10.9091H14.5451V12.3637H13.8178V13.0909H13.0906V13.8182H12.3633V14.5455H10.9087V15.2727H5.09056V14.5455H3.63602V13.8182H2.90875V13.0909H2.18147V12.3637H1.4542V10.9091H0.726929V5.09093H1.4542V3.63639H2.18147V2.90911H2.90875V2.18184H3.63602V1.45457H5.09056V0.727295H10.9087ZM10.1815 2.18184H5.81784V2.90911H4.36329V3.63639H3.63602V4.36366H2.90875V5.8182H2.18147V10.1818H2.90875V11.6364H3.63602V12.3637H4.36329V13.0909H5.81784V13.8182H10.1815V13.0909H11.636V12.3637H12.3633V11.6364H13.0906V10.1818H13.8178V5.8182H13.0906V4.36366H12.3633V3.63639H11.636V2.90911H10.1815V2.18184Z"
          fill="#E7E7E7"
        />
        <rect x="6.5484" y="3.95605" width="3.16483" height="0.879121" fill="#E7E7E7" />
        <rect x="6.5484" y="10.9451" width="3.16483" height="0.879121" fill="#E7E7E7" />
        <rect x="5.88885" y="4.39563" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="6.02087" y="7.29669" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="6.02087" y="10.5055" width="1.01099" height="0.923077" fill="#E7E7E7" />
        <rect x="6.328" y="8.04395" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="6.328" y="10.1099" width="1.01099" height="0.527473" fill="#E7E7E7" />
        <rect x="6.63599" y="8.70331" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="6.63599" y="9.71436" width="1.01099" height="0.571429" fill="#E7E7E7" />
        <rect x="5.66888" y="4.83514" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="4.52618" y="5.45062" width="1.01099" height="0.703297" fill="#E7E7E7" />
        <rect x="4.26263" y="7.12091" width="0.879121" height="0.879121" fill="#E7E7E7" />
        <rect x="4.61334" y="7.78021" width="0.835165" height="0.879121" fill="#E7E7E7" />
        <rect x="5.40491" y="8.52747" width="1.01099" height="0.43956" fill="#E7E7E7" />
        <rect x="3.99866" y="6.15387" width="0.879121" height="1.03201" fill="#E7E7E7" />
        <rect x="5.66888" y="6.76929" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="9.31732" y="4.39563" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="9.22931" y="7.29669" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="9.22931" y="10.5055" width="1.01099" height="0.923077" fill="#E7E7E7" />
        <rect x="8.92133" y="8.04395" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="8.92133" y="10.1099" width="1.01099" height="0.527473" fill="#E7E7E7" />
        <rect x="8.5694" y="8.70331" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="8.5694" y="9.71436" width="1.01099" height="0.571429" fill="#E7E7E7" />
        <rect x="7.16315" y="9.14288" width="1.75824" height="0.659341" fill="#E7E7E7" />
        <rect x="9.53687" y="4.83514" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="10.68" y="5.45062" width="1.01099" height="0.703297" fill="#E7E7E7" />
        <rect x="10.812" y="7.12091" width="0.879121" height="0.879121" fill="#E7E7E7" />
        <rect x="10.548" y="7.78021" width="0.835165" height="0.879121" fill="#E7E7E7" />
        <rect x="9.80048" y="8.52747" width="1.01099" height="0.43956" fill="#E7E7E7" />
        <rect x="11.1635" y="6.15387" width="0.879121" height="1.03201" fill="#E7E7E7" />
        <rect x="9.53687" y="6.76929" width="1.01099" height="0.879121" fill="#E7E7E7" />
        <rect x="5.53687" y="5.01105" width="1.01099" height="1.97802" fill="#E7E7E7" />
        <rect x="9.66888" y="5.01105" width="1.01099" height="1.97802" fill="#E7E7E7" />
      </g>
      <defs>
        <clipPath id="clip0_282_9329">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoinIcon;
