export const formatNumber = (number: number,multiplier:number|null): string => {

  if(multiplier){
    return new Intl.NumberFormat('fr-FR').format(number + number * multiplier / 100);
      }
  return new Intl.NumberFormat('fr-FR').format(number);
};

export function formatNumberToK(num: number,multiplier:number|null) {

  if(multiplier){
    if ((num + num * multiplier / 100 )< 2000) {

      return (num + num * multiplier / 100 ).toString();
    }
    if ((num + num * multiplier / 100) >= 1000000) {
      return ((num + num * multiplier / 100) / 1000000).toFixed(0) + 'M';
    } else if ((num + num * multiplier / 100) >= 1000) {
      return ((num + num * multiplier / 100) / 1000).toFixed(0) + 'k';
    } else {
     
      
      return (num + num * multiplier / 100).toString();
      }
    }


  if (num < 2000) {
    return num.toString();
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(0) + 'k';
  } else {
    return num.toString();
  }
}
