import { useQuery } from 'react-query';
import api from '../../shared/utils/api';

type ILeader = {
  name: string;
  action_type?: string;
  result_url: string;
  id: number;
};

export type IParticipantList = {
  leaders: Array<ILeader>;
  current_user: {
    action_type?: string;
    coins_count: number;
    id: number;
    name: string;
    place_num?: string;
    referrer_coins?: number;
    result_url?: string;
    shakes_count: number;
    shaking_time: number;
  };
};

export const useTournamentParticipants = (tournamentId: string) => {
  return useQuery({
    queryKey: ['participants'],
    queryFn: async () => {
      const res = await api.get<IParticipantList>(
        '/tournaments/' + tournamentId + '/participants/',
      );

      return res;
    },
    retry: false,
    refetchInterval: 100000,
  });
};
