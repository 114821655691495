import loaderImg from '../assets/loader-optimized.gif';

const Loader = () => {
  return (
    <div className="flex fixed top-0 left-0 justify-center items-center h-screen w-full text-[#FFFFFFBF]">
      <div className="flex flex-col items-center">
        <img src={loaderImg} width={200} alt="loader" />
      </div>
    </div>
  );
};

export default Loader;
