import { useEffect, useState } from 'react';
import Router from './Router';
import Loader from './Loader';
import useAuthStore from '../shared/hooks/useAuthStore';

import api from '../shared/utils/api';
import ErrorPage from '../modules/Error';

function App() {
  const { login, isLoggedIn } = useAuthStore();
  const [isError, setIsError] = useState(false);
  const [referralSent, setReferralSent] = useState(false);

  useEffect(() => {
    const WebApp = window.Telegram.WebApp;

    // Initiate the WebApp
    WebApp.ready();

    WebApp.disableVerticalSwipes();
    WebApp.enableClosingConfirmation();

    // Access theme params
    const themeParams = WebApp.themeParams || {};

    WebApp.headerColor = '#000000';

    // Override the secondary background color
    document.documentElement.style.setProperty(
      '--tg-theme-secondary-bg-color',
      themeParams.secondary_bg_color || '#000000',
    );

    const data = WebApp.initData;

    window.Telegram.WebApp.expand();

    const url = '/users/login?' + data;

    api
      .get<{ token: string }>(url)
      .then((res: { token: string }) => {
        login(res.token);
        // @ts-ignore
        window.dataLayer.push({ event: 'test_login', eventName: 'test_login' });
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
    if (!referralSent && startParam && isLoggedIn) {
      setReferralSent(true);
      api.post('/users/set_referral', {
        is_premium: window.Telegram.WebApp.initDataUnsafe.user?.is_premium || false,
        referral: startParam,
      });
    }
  }, [isLoggedIn]);

  if (isError) {
    return <ErrorPage />;
  }

  if (!isLoggedIn) {
    return <Loader />;
  }

  return <Router />;
}

export default App;
