import { twMerge } from 'tailwind-merge';

import { FriendsIcon } from './navigationIcons/Friends';
import { ShakeIcon } from './navigationIcons/Shake';
import { TournamentsIcon } from './navigationIcons/Tournaments';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCoinsStore } from '../../shared/hooks/useShakesDetection';

// Consider moving to routerObject
const navigationItems = [
  {
    link: '/tournaments',
    name: 'Tournaments',
    icon: TournamentsIcon,
  },
  {
    link: '/earn',
    name: 'Earn',
    icon: ShakeIcon,
  },
  {
    link: '/friends',
    name: 'Friends',
    icon: FriendsIcon,
  },
];

const NavigationMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { requestAccess } = useCoinsStore();

  return (
    <div className="bg-[#1A1A1A] h-[10%] flex flex-col z-20 font-[SFPro]">
      <div className="flex justify-between mt-[">
        {navigationItems.map(({ name, link, icon: Icon }) => (
          <div
            key={name}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              navigate(link);
              requestAccess();
            }}
            className="w-[33%] flex justify-center mt-2"
          >
            <div className="flex flex-col items-center">
              <Icon size={24} isActive={pathname.includes(link) && link !== '/'} />
              <div
                className={twMerge(
                  'text-[#868686] text-[10px]',
                  pathname.includes(link) && link !== '/' && 'text-white',
                )}
              >
                {name}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default NavigationMenu;
