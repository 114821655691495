import { useQuery } from 'react-query';
import api from '../../shared/utils/api';

export type Leader = {
  id: number;
  name: string;
  coins_count: number;
  shakes_count: null;
  shaking_time: number;
  place_num: number;
  referrer_coins: null;
  coins_shaked: null;
  action_type: null;
  result_url: null;
};

type Payload = {
  leaders: Array<Leader>;
  current_user?: Leader;
  total_count: number;
};

export const useLeaders = () => {
  return useQuery<Payload>(['leaders'], {
    queryFn: async () => {
      const res = await api.get<Payload>('/users/leaders?skip=0&limit=100');
      return res;
    },
    retry: false,
    refetchInterval: 100000,
    // placeholderData: {
    //   leaders: [],
    // },
  });
};
