import InfoSlider from './components/InfoSlider';
import ProfileData from './components/ProfileData';
import TabsMenu from './components/TabsMenu';

const TournamentPage = () => {
  return (
    <div className="h-full">
      <div className="h-[258px] flex flex-col">
        <ProfileData />
        <InfoSlider />
      </div>
      <TabsMenu />
    </div>
  );
};

export default TournamentPage;
