import { Outlet, useNavigate } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { useEffect, type PropsWithChildren } from 'react';
import { useInitStore } from '../../shared/hooks/useAppInit';
import Loader from '../Loader';

const MainLayout = ({ children }: PropsWithChildren) => {
  const { initApp, initialized } = useInitStore();
  const navigate = useNavigate();

  const content = children || <Outlet />;

  useEffect(() => {
    if (!initialized) {
      initApp();
      navigate('/');
    }
  }, [initialized]);

  if (!initialized) {
    return <Loader />;
  }
  return (
    <div className="flex flex-col bg-black h-full">
      <div className=" h-[90%] px-4">{content}</div>
      <NavigationMenu />
    </div>
  );
};

export default MainLayout;
